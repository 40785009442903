<template>
    <div class="full-height color-txt">
        <s-form @submit="addItem">
            <div class="row">
                <div class="col-12">
                    <h3 class="text-primary fs-lg-4 font-poppins-semibold">Dehorning/Disbudding</h3>
                </div>
                <div class="col-lg-2 mt-lg-5">
                    <validated-ajax-vue-select class="c-input-select input-border-primary field-required" placeholder="Shed"
                                               v-model="shed_number" @input="loadCowOptions" label="Shed"
                                               :url="shedOptions" :rules="{required:true}"></validated-ajax-vue-select>
                </div>

                <!--                <div class="col-4">-->
                <!--                <custom-two-column-table></custom-two-column-table>-->
                <!--            </div>-->
            </div>

            <p class="fs-lg-2 font-poppins-semibold text-primary"> Enter Individually</p>
            <div class="row">
                <div class="col-lg-2">
                    <validated-vue-select :options="cowOptions" @input="loadDetails" class="c-input-select field-required"
                                          label="Animal No / Name" v-model="details.animalno_or_name" :rules="{required:true}"></validated-vue-select>
                </div>
                <div class="col-lg-2">
                    <validated-input label="Renumber" v-model="details.renumber" disabled></validated-input>
                </div>
                <div class="col-lg-2">
                    <validated-input label="Shed No" v-model="details.shed_number" disabled></validated-input>
                </div>
                <div class="col-lg-2">
                    <validated-input label="Breed" v-model="details.breed" disabled></validated-input>
                </div>
                <div class="col-lg-2">
                    <validated-date-picker format="DD-MM-YYYY" class="c-input-datepicker" label="Date of Birth" v-model="details.dob" disabled></validated-date-picker>
                </div>
                <div class="col-lg-2">
                    <validated-input label="Age" v-model="model.age" disabled></validated-input>
                </div>
                <div class="col-lg-2">
                    <validated-ajax-vue-select  :url="dehorningOptions" class="c-input-select field-required" label="Dehorning Type" v-model="model.dehorning_type" :rules="{required:true}"></validated-ajax-vue-select>
                </div>
                <div class="col-lg-2">
                    <validated-date-picker format="DD-MM-YYYY" class="c-input-datepicker field-required" label="Dehorning Date" v-model="model.dehorningdate" :rules="rules.DehornDate"></validated-date-picker>
                </div>
            </div>
            <div class="row mt-lg-9">
                <div class="col-12">
                    <div class="fl-x fl-j-e">
                       <div class="btn-group">
                        <btn text="Save" :loading="loading" loading-text="Saving..." class="px-4"></btn>
                        <btn text="Cancel" type="reset" @click="BackToHome" design="basic-b" class="px-4"></btn>
                   </div>
                    </div>
                </div>
            </div>
        </s-form>
    </div>
</template>

<script>
// import CustomTwoColumnTable from '@components/ui/CustomTwoColumnTable';
import urls from '../../../data/urls';
import masterURLs from '../../../data/masterURLs';
import axios from 'secure-axios';

export default {
    name: 'CowsDehorningFirstPage',
    data () {
        return {
            shed_number: '',
            loading: false,
            URL: urls.cowDehorning.addEdit,
            dehorningOptions: masterURLs.master.dehorningType.vueSelect,
            details: {
                renumber: '',
                breed: '',
                dob: '',
                shed_number: '',
                animalno_or_name: ''

            },
            model: {
                cow: '',
                age: '',
                dehorning_type: '',
                dehorningdate: ''
            },
            shedOptions: masterURLs.master.shed.vueSelect + '?animal=cow',
            cowOptions: [],
            rules: {
                common: {
                    required: true,
                    customValidator: (value) => {
                        return this.Validation(value);
                    }
                },
                DehornDate: {
                    required: true,
                    customValidator: (value) => {
                        return this.DhorningValidation(value);
                    }
                }
            }
        };
    },

    methods: {
        BackToHome () {
            this.$router.push({ path: '/ah-section/' });
        },
        async loadCowOptions () {
            this.dataclear();
            const response = await axios.get(urls.cows.vueSelect + '?shed_number=' + this.shed_number);
            this.cowOptions = response.data.data;
        },
        async loadDetails () {
            const response = await axios.form(urls.cows.details, { id: this.details.animalno_or_name });
            const data = response.data.data;
            this.model.cow = data.id;
            this.model.age = data.age;
            this.details.renumber = data.renumber;
            this.details.breed = data.breed.breed_name;
            this.details.dob = data.dob;
            this.details.shed_number = data.shedno.name;
            // this.details.animalno_or_name = data.animalno_or_name;
        },
        dataclear () {
            this.model.age = '';
            this.details.dob = '';
            this.details.renumber = '';
            this.details.breed = '';
            this.details.shed_number = '';
            this.model.cow = '';
            this.details.animalno_or_name = '';
            this.model.dehorning_type = '';
            this.model.dehorningdate = '';
        },
        async addItem () {
            try {
                this.loading = true;
                const response = await axios.form(this.URL, this.model);
                const result = response.data;
                if (result.success) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    await this.$router.push({ path: '/cows-list/' });
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}: ${errors[key]}`;
                            this.$notify('Warning', errorMessage, { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },

        Validation (date) {
            const dob = this.details.dob;
            const dateParts = date.split('-');
            if (dateParts.length !== 3) {
                return 'Please enter a valid AI date in the format DD-MM-YYYY.';
            }
            const aiDay = parseInt(dateParts[0], 10);
            const aiMonth = parseInt(dateParts[1], 10);
            const aiYear = parseInt(dateParts[2], 10);

            const dobParts = dob.split('-');
            if (dobParts.length !== 3) {
                return 'Please enter a valid DOB in the format DD-MM-YYYY.';
            }
            const dobDay = parseInt(dobParts[0], 10);
            const dobMonth = parseInt(dobParts[1], 10);
            const dobYear = parseInt(dobParts[2], 10);

            if (isNaN(aiDay) || isNaN(aiMonth) || isNaN(aiYear) || isNaN(dobDay) || isNaN(dobMonth) || isNaN(dobYear)) {
                return 'Please enter valid dates in the format DD-MM-YYYY.';
            }

            const dateObj = new Date(aiYear, aiMonth - 1, aiDay);
            const dobDateObj = new Date(dobYear, dobMonth - 1, dobDay);

            if (dateObj <= dobDateObj) {
                return 'Date should be after the date of birth (DOB).';
            }

            return true;
        },

        DhorningValidation (date) {
            const dob = this.details.dob;
            const dateParts = date.split('-');
            if (dateParts.length !== 3) {
                return 'Please enter a valid AI date in the format DD-MM-YYYY.';
            }
            const aiDay = parseInt(dateParts[0], 10);
            const aiMonth = parseInt(dateParts[1], 10);
            const aiYear = parseInt(dateParts[2], 10);

            const dobParts = dob.split('-');
            if (dobParts.length !== 3) {
                return 'Please enter a valid DOB in the format DD-MM-YYYY.';
            }
            const dobDay = parseInt(dobParts[0], 10);
            const dobMonth = parseInt(dobParts[1], 10);
            const dobYear = parseInt(dobParts[2], 10);

            if (isNaN(aiDay) || isNaN(aiMonth) || isNaN(aiYear) || isNaN(dobDay) || isNaN(dobMonth) || isNaN(dobYear)) {
                return 'Please enter valid dates in the format DD-MM-YYYY.';
            }

            const dateObj = new Date(aiYear, aiMonth - 1, aiDay);
            const dobDateObj = new Date(dobYear, dobMonth - 1, dobDay);

            if (dateObj <= dobDateObj) {
                return 'Date should be after the date of birth (DOB).';
            }
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            if (dateObj > currentDate) {
                return 'Future Date not allowed.';
            }

            return true;
        }
    }
};
</script>

<style scoped lang="scss">
.list-items {
        li {
            margin-top: var(--spacer-4);
            font-size: 0.9rem;
            font-family: poppins-medium;
        }
    }
</style>
